export const TypeOptions = ['Observed', 'Quantitative']

export const TrendOptions = ['Positive', 'Stable', 'Negative']

export const ComparisonOptions = [
  'None',
  'Before Measurement',
  'Comparison Site',
  'Literature Value',
  'International Standard'
]
